@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
    @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700;800;900&display=swap");
    @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
}

html,
body {
    overflow-x: hidden;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

html::-webkit-scrollbar {
    display: block;
}

.video-responsive {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
}
.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.atoken-illustration {
    background: url("images/services/serviceat.png");
}

.deconversion-illustration {
    background: url("images/services/servicedec.png");
}

.cmining-illustration {
    background: url("images/services/servicecm.png");
}

.atoken-illustration,
.deconversion-illustration,
.cmining-illustration {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
